var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex mb-2 w-100 option",staticStyle:{"height":"80px !important"},on:{"click":function($event){return _vm.updateValue()}}},[_c('div',{class:`boxes w-95 ps-2 ${_vm.value && !_vm.subdescription ? 'checked' : ''}`,staticStyle:{"margin-right":"0.7rem","width":"95%"}},[_c('div',{staticStyle:{"width":"25%"}},[(_vm.logo!==null)?_c('img',{staticClass:"imgLogo",staticStyle:{"display":"none","max-height":"55px"},attrs:{"src":_vm.isLocalImg ? _vm.logo : `${_vm.urlBucket}/${_vm.logo}`},on:{"error":(e) => {
          e.target.src = `${_vm.urlBucket}/${_vm.imgOnError}`;
          _vm.hideSpinner(`${_vm.informName}${_vm.description.replace(/ /g, '-')}`);
        },"load":(e) => {
          e.target.style.display = 'unset';
          _vm.hideSpinner(`${_vm.informName}${_vm.description.replace(/ /g, '-')}`);
        }}}):_vm._e()]),_c('div',{staticClass:"text-center mx-2",attrs:{"id":`${_vm.informName}${_vm.description.replace(/ /g, '-')}`}},[(_vm.logo!==null)?_c('img',{attrs:{"src":"/assets/img/spinner-loading.gif","width":"30px","alt":""}}):_vm._e()]),_c('div',{staticStyle:{"width":"75%"}},[_c('p',{staticClass:"mb-0 fs-md",staticStyle:{"font-weight":"600","font-size":"18px"}},[_vm._v(_vm._s(_vm.description))]),_c('p',{staticClass:"mb-0 fs-xs"},[_vm._v(" "+_vm._s(_vm.subdescription)+" ")])])]),_c('div',{class:`boxes justify-content-center ${_vm.value ? 'checked' : ''}`,staticStyle:{"width":"80px"}},[(_vm.value)?_c('img',{attrs:{"src":require('@/assets/check.png'),"width":"41px","height":"33px"}}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }